@import '../variables';

.FixedHeader {
  background: white;
  z-index: 99;
  margin: auto;
  width: 100vw;
  position: fixed;
  top: -80px;
  transition: all 600ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &.show {
    top: $mobileNavHeight;
    @media (min-width: $md) {
      top: $combinedAuthenticatedNavHeight;
    }
  }

  &.hide {
    top: -30px;
  }
}

.FixedHeader__content {
  max-width: $maxPageWidth;
  margin: auto;
}
