@import '../variables';

.CheckboxLabel__label {
  font: $font__bodyRegular;
  color: $fbGrey__dark;
}

.CheckboxLabel__root {
  border-radius: $borderRadius__button;
  margin: 0;
  padding: 5px;
  padding-right: 24px;
  width: 100%;

  &:hover {
    background: $fbGrey__lightest;

    .CheckboxLabel__label {
      color: $fbGreen__standard !important;
      font: $font__bodyBold !important;
    }
  }

  &.small {
    padding: 0;
  }
}
