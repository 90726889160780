@import '../../../stylesheets/variables';

.ButtonContainer {
  display: flex;
  margin: auto;
  flex-direction: column-reverse;
}

.BackButton {
  margin: 12px 0 0;
  flex: 0.35;
  border: none;
  background: transparent;
  border-bottom: 2px solid $fbGreen__light;
  cursor: pointer;
  font-size: 16px;

  @media (min-width: $sm) {
    margin-right: auto;
  }
}

.SubmitButton {
  margin: 12px 0 0;
  flex-grow: 1;
  background: $fbGreen__light !important;
  border: none !important;
  color: $fbGrey__white !important;
  width: 100%;

  &:disabled {
    border: 1px solid $fbGrey__light;
  }

  &:hover {
    box-shadow: $mediumBoxShadow;
    background: $fbGreen__light;
  }

  svg {
    color: $fbGrey__white !important;
    margin: 0 !important;
  }
}

.OrangeLink {
  color: $fbOrange__standard;
  text-decoration: underline;

  &:hover,
  &:focus,
  &:active {
    color: $fbOrange__dark;
    text-decoration: underline;
  }
}

.InputRow {
  flex-wrap: wrap;
  width: 100%;
  display: flex;

  @media (min-width: $md) {
    flex-wrap: nowrap;
  }
}

.Input__halfWidth {
  width: 100%;

  @media (min-width: $md) {
    flex: 1;

    &:first-child {
      margin-right: 8px;
    }

    &:last-child {
      margin-left: 8px;
    }
  }
}

.Input__fullWidth {
  width: 100%;
}

.AddressErrorNotification {
  width: 100%;
  margin: auto;
}

.SelectedAddress {
  background: $fbGreen__lightest;
  text-align: center;
  color: $fbGreen__dark;
  padding: 12px;
  margin-top: 8px;
  border-radius: $borderRadius__button;
  width: 100%;
  font: $font__bodyRegular;
  border: 1px solid;
}

.ReturnToAddressSearch__ButtonContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.ButtonLink {
  color: $fbGreen__light;
  text-decoration: underline;
  border: none;
  background-color: inherit;

  &:hover,
  &:focus,
  &:active {
    color: $fbGreen__standard;
    text-decoration: underline;
    outline: none;
  }
}

.SuburbSearchField {
  width: 100%;
}

.SuburbSearch__inputContainer {
  font: $font__bodyRegular;
  border-radius: $borderRadius__button;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: white;
  position: relative;
}

.ButtonContainerOverride {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-self: flex-end;
  align-items: flex-end;

  &.ManualAddress__extraHeight {
    height: 180px;
  }

  &.ManualAddress__maxHeight {
    height: 130px;
  }

  @media (min-width: $md) {
    height: auto;
  }
}

.BackButtonOverride {
  margin: 12px 0 0;
  flex: 0.6;
  margin-right: 16px;

  @media (min-width: $md) {
    flex: 0.2;
  }
}

.SubmitButtonOverride {
  margin: 12px 0 0;
  flex: 0.6;

  @media (min-width: $md) {
    flex: 0.15;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
    box-shadow: none;
    background: $fbGrey__light;

    &:hover {
      background: $fbGrey__light;
    }
  }
}

.FormOverride {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.NegativeMargin {
  margin-top: -8px;
}
