@import '../variables';

.Badge {
  font: $font__bodyBold;
  color: white;
  background: $fbGreen__standard;
}

.Root__standalone {
  margin-left: 8px;
  margin-right: 20px;
}
