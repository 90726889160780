@import '../variables';

.Checkbox__root {
  color: $fbGrey__light;

  &:hover {
    background-color: $fbGreen__transparent;
  }

  &.checked {
    color: $fbGreen__standard;

    &:hover {
      background-color: $fbGreen__transparent;
    }

    &.disabled {
      color: $fbGrey__light;
    }
  }
}

.Label {
  color: $fbGrey__dark;
  font: $font__bodyRegular;
  line-height: 140%;

  &.disabled {
    color: $fbGrey__standard;
    cursor: not-allowed;
    pointer-events: unset;
  }
}
