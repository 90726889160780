@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');

$desktopNavHeight: 64px;
$mobileNavHeight: 48px;
$dashboardNavigationHeight: 44px;
$combinedAuthenticatedNavHeight: 108px;
$footerHeight: 250px;

// ---- BETA - TabBarBubble
$tabBarHeight: 56px;
$iOSOffsetHeight: 48px;
$maxPageWidth: 1230px;
$font__bodyRegular: 400 16px 'Source Sans Pro';
$font__bodyMediumBold: 600 16px 'Source Sans Pro';
$font__bodyBold: 700 16px 'Source Sans Pro';
$font__bodyBoldLarge: 700 18px 'Source Sans Pro';
$font__headingLight: 300 12px 'Montserrat';
$font__headingRegular: 400 12px 'Montserrat';
$font__headingMedium: 500 12px 'Montserrat';
$font__headingSemiBold: 600 12px 'Montserrat';
$font__headingBold: 700 12px 'Montserrat';
$font__headingWidget: 700 18px 'Montserrat';

// ------ Z Indexes ------
$zIndex__navBar: 1450;
$zIndex__cart: 1456; // More than nav & motion container
$zIndex__productPortionSelect: 1452;
$zIndex__modalPageSlideHeader: 1350;
$zIndex__modalAboveNav: 5010;
$zIndex__floatingBanner: 9999;

// ------ Transition Times ------
$navigationTransitionTime: 200ms;

// ------ Borders ------
$borderRadius__button: 4px;
$borderRadius__modal: 8px;
$borderRadius__leftDrawer: 0 12px 12px 0;
$borderRadius__rightDrawer: 12px 0 0 12px;
$sideNavFullWidth: 274px;
$sideNavMinimisedWidth: 112px;

// ------ Breakpoints ------
$xs: 320px;
$sm: 480px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

// ------ New Palette ------
$slideOverBackground: #fafafa;
$fbOwner: #a8ffea;
$fbManager: #ffb0b0;
$fbOperator: #e8f000;

// Greys
$fbGrey__black: #000;
$fbGrey__dark: #485146;
$fbGrey__standard: #6e7370;
$fbGrey__light: #bbb;
$fbGrey__lighter1x: #e6e6e6;
$fbGrey__lighter: #e7e7e7;
$fbGrey__lighter2x: #e8e8e8;
$fbGrey__lighter3x: #f9f9f9;
$fbGrey__lightest: #f7f7f7;
$fbGrey__white: #fff;

// Oranges
$fbOrange__dark: #965100;
$fbOrange__standard: #ff8a00;
$fbOrange__light: #ffb661;
$fbOrange__lightest: #fff4ec;

// Greens
$fbGreen__dark: #006b33;
$fbGreen__standard: #009447;
$fbGreen__light: #00c65f;
$fbGreen__lightest: #edfff6;
$fbGreen__transparent: rgb(0 149 100 / 8%);
$fbGreen__illustration: #b0ffd8;

// Reds
$fbRed__dark: #8b0000;
$fbRed__standard: #d32f2f;
$fbRed__light: #ff6261;
$fbRed__lightest: #fff4f4;

// Yellow
$fbYellow__dark: #775f00;
$fbYellow__standard: #bf9900;
$fbYellow__light: #fc0;
$fbYellow__lightest: #fff9e2;

// Blues
$fbBlue__lightest: #eaf9ff;
$fbBlue__light: #31c1ff;
$fbBlue__standard: #07a;
$fbBlue__dark: #004a6a;
$fbTiel__standard: #14ab9a;
$fbTeal__lightest: #e7fffc;
$fbTeal__dark: #086a5f;

// ------ Shadows ------
$lightBoxShadow: 0 0 8px $fbGrey__lightest;
$mediumLightBoxShadow: 0 0 8px 0 #c5c5c547;
$mediumBoxShadow: 0 0 8px $fbGrey__light;
$darkBoxShadow: 0 2px 8px $fbGrey__standard;
$inactiveBoxShadow: 0 0 8px rgb(197 197 197 / 28%);
$drawerBoxShadow: 0 0 8px rgb(0 0 0 / 25%);

// background-image colour gradients
$verticalGreenGradient: linear-gradient(0deg, #009447 0%, #00c65f 100%);
$horizontalGreenGradient: linear-gradient(to right, $fbGreen__lightest 0%, $fbGreen__light 100%);
