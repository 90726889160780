@import '../variables';

.NotificationSection {
  padding: 12px;
  display: flex;
  text-align: left;
  border-radius: $borderRadius__button;
  box-shadow: $lightBoxShadow;
  background: $fbRed__lightest;
  font: $font__bodyRegular;
  color: $fbGrey__dark;
  font-size: 14px;
  position: relative;
  margin-top: 16px;
  margin-bottom: 16px;

  &.large {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 20px;

    span {
      font-size: 16px;
    }
  }

  &.error {
    background: $fbRed__lightest;
    color: $fbRed__standard;
  }

  &.warning {
    background: $fbYellow__lightest;
    color: $fbYellow__dark;
  }

  &.success {
    background: $fbGreen__lightest;
    color: $fbGreen__dark;
  }

  &.info {
    background: $fbBlue__lightest;
    color: $fbBlue__dark;
  }
}

.ColorStrip {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  border-radius: 4px 0 0 4px;
  background: $fbRed__standard;

  &.error {
    background: $fbRed__standard;
  }

  &.warning {
    background: $fbYellow__dark;
  }

  &.success {
    background: $fbGreen__dark;
  }

  &.info {
    background: $fbBlue__dark;
  }
}

.ContentContainer {
  padding-right: 8px;
}

.ContentAndActionsContainer {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  flex: 1;
  @media (min-width: $md) {
    flex-wrap: nowrap;
  }
}

.Title {
  font: $font__bodyBold;
}

.Icon {
  margin-right: 8px;
}

.ActionButton {
  margin: auto 0;
  width: 100%;
  padding: 8px 12px;
  @media (min-width: $md) {
    width: unset;
    padding: 12px 16px;
  }
}

.IconButton {
  margin-bottom: auto;
  margin-left: 8px;
}
