@import '../variables';

.ChevronButton {
  min-height: 44px;
}

.ChevronButton__icon {
  transition: transform $navigationTransitionTime;
  font-size: 1rem !important;

  &.flipped {
    transform: scaleY(-1);
  }
}
