@import '../../../../stylesheets/variables';

.AddressErrorNotification {
  width: 100%;
  margin: auto;
}

.ButtonLink {
  color: $fbRed__standard;

  &.Warning {
    color: $fbYellow__standard;
  }

  text-decoration: underline;
  border: none;
  background-color: inherit;

  &:hover,
  &:focus,
  &:active {
    color: $fbRed__dark;

    &.Warning {
      color: $fbYellow__dark;
    }

    text-decoration: underline;
    outline: none;
  }
}

.NegativeMargin {
  margin-top: -8px;
}
