@import '../../stylesheets/variables';

.GradientContainer {
  background: linear-gradient(198.23deg, #c6fff9 0%, #00c65f 165.73%);
  width: 100vw;
  min-height: 100vh;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
}

.SolidContainer {
  background: rgb(255 255 255 / 60%);
  width: 90%;
  height: 90%;
  min-height: 90vh;
  margin: 30px;
  max-height: 90%;
  padding: 12px;
  border-radius: $borderRadius__modal;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.ContentContainer {
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  padding-top: 16px;
  max-width: 85vw;
  width: 450px;
}

.FoodbombLogo {
  width: 60%;
  max-width: 60%;
}

.HeaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 150px;
  justify-content: space-around;
}

.ActionsContainer {
  button {
    margin-left: 0;
  }
}
