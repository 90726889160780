@import '../variables';

/* stylelint-disable no-descending-specificity */

.StatusChipContainer {
  display: inline-block;
  margin: 8px;
}

.StatusChip {
  padding: 4px 8px;
  border-radius: $borderRadius__button;
  display: flex;
  align-items: center;
  font: $font__bodyRegular;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.03em;
  font-variant: normal !important;
  text-transform: capitalize !important;

  &.success {
    background: $fbGreen__lightest;
    color: $fbGreen__dark;

    .StatusChip__icon {
      color: $fbGreen__standard;
    }
  }

  &.pending {
    background: $fbYellow__lightest;
    color: $fbYellow__dark;

    .StatusChip__icon {
      color: $fbYellow__standard;
    }
  }

  &.failed {
    background: $fbRed__lightest;
    color: $fbRed__dark;

    .StatusChip__icon {
      color: $fbRed__standard;
    }
  }

  &.info {
    background: $fbBlue__lightest;
    color: $fbBlue__dark;

    .StatusChip__icon {
      color: $fbBlue__standard;
    }
  }

  &.default {
    background: $fbGrey__lightest;
    color: $fbGrey__dark;

    .StatusChip__icon {
      color: $fbGrey__standard;
    }
  }
}

.StatusChip__icon {
  width: 18px;
  height: 18px;
  margin-right: 8px;
  margin-left: 2px;
}
