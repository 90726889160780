@import '../../stylesheets/variables';

/* stylelint-disable at-rule-no-unknown */

.LoginPageContainer {
  display: flex;
  justify-content: center;
  background: $fbGrey__white;
  min-height: calc(100vh - 48px);
  @media (min-width: $md) {
    min-height: calc(100vh - 64px);
  }
}

.LoginPageContainer__left {
  background: white;
  width: 100%;
  display: flex;
  @media (min-width: $md) {
    width: 50%;
  }
}

.LoginPageContainer__right {
  background: white;
  width: 100%;
  display: flex;
  @media (min-width: $md) {
    background: $fbGrey__lightest;
    width: 50%;
    padding-left: 8px;
    padding-right: 8px;
  }
}

.HideOnMobile {
  display: none;
  @media (min-width: $md) {
    display: block;
  }
}

.LoginPageBanner {
  background: $fbGreen__standard;
  padding-top: 20px;
  padding-bottom: 20px;

  &.Red {
    background: $fbRed__standard;
  }

  &.Yellow {
    background: $fbYellow__lightest;
  }
}

.BannerText {
  color: $fbGrey__white;
  text-align: center;

  &.Pending {
    color: $fbGrey__dark;
  }
}

.EmbeddedFrame {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
}
