@import '../variables';

.JoinedToggleButtonContainer {
  display: flex;
  height: 44px;
  min-width: 300px;
}

.JoinedToggleButton {
  height: 44px !important;
  margin: 0 !important;
  width: 50% !important;
  letter-spacing: 0.03rem;

  &:first-child {
    border-radius: $borderRadius__button 0 0 $borderRadius__button;
  }

  &:last-child {
    border-radius: 0 $borderRadius__button $borderRadius__button 0;
  }
}
