@import '../../stylesheets/variables';

.SignUpFormPaper {
  padding: 54px 16px 16px;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: $md) {
    margin: auto;
    padding: 24px;
    margin-top: 100px;
    min-width: 600px;
  }
  @media (min-width: $lg) {
    padding: 40px;
    min-width: 600px;
  }
}

.SectionContainer {
  margin-top: 90px;
}

.SignUpFormContainer {
  max-width: 600px;
  margin-top: 64px;
}

.PostcodeSuccess__Subheading {
  font: $font__headingRegular;
  font-size: 18px;
  margin-bottom: 20px;
}

.PostcodeSuccess__ButtonContainer {
  display: flex;
  margin: auto;
  flex-direction: column;
}

.PostcodeSuccess__BackButton {
  flex: 0.35;
  margin-left: 0;
  margin-right: 0;
}

.PostcodeSuccess__SignUpButton {
  flex: 0.65;
  margin-left: 0;
  margin-right: 0;
  background: $fbGreen__light !important;
  border: none !important;
  color: $fbGrey__white !important;
}

.CenteredButton {
  margin: auto;
}

.SignUp__Stepper {
  display: none;
  background: transparent;
  width: 50%;
  position: absolute;
  margin-top: 60px;
  margin-bottom: 60px !important;
  top: 0;
  @media (min-width: $md) {
    display: flex;
  }
}

.Step__GreenOverride {
  color: $fbGreen__light !important;
}

.StepLabel__Inactive {
  color: $fbGrey__light;
}

.MobileStepper {
  display: flex;
  justify-content: space-between;
  top: $mobileNavHeight;
  background: white;
  @media (min-width: $md) {
    display: none;
  }
}

.MobileStepper__DotActive {
  background-color: $fbGreen__standard;
}

.CustomerTypeHeading,
.SupplierCheck__heading,
.SuccessTitle {
  font: $font__headingWidget;
  color: $fbGrey__black;
  font-weight: 900;
  font-size: 34px;
  text-transform: uppercase;
  width: 100%;
}

.SupplierCheck__subheading,
.PostcodeCheck__Description {
  font: $font__headingRegular;
  color: $fbGrey__black;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 16px;
}

.MobileStepperButton {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
}

.MobileStepperButton__Text {
  color: $fbGrey__standard;
}

.MobileStepperButton__Icon {
  color: $fbGrey__standard;
}

.ButtonPlaceholder {
  width: 70px;
}
