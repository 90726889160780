@import '../variables';

.FormikSingleSelectField {
  &.disabled {
    cursor: not-allowed;
  }
}

.Input {
  width: 100%;
}

.InputLabel {
  margin-left: 8px;
  font: $font__bodyRegular;
  font-size: 14px;
  line-height: 140%;
  color: $fbGrey__standard;

  &.error {
    color: $fbRed__standard !important;
  }
}

.ErrorMessageContainer {
  min-height: 24px;
  padding: 4px 8px;
  font: $font__bodyRegular;
  font-size: 12px;
  line-height: 140%;
  color: $fbRed__standard !important;
}
