@use 'sass:color';
@import '../../../../stylesheets/variables';

.LocationSearch__inputContainer {
  font: $font__bodyRegular;
  border-radius: $borderRadius__button;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: white;
  position: relative;
}

.LocationSearch__inputField {
  flex: 1;
  box-sizing: border-box;
  padding: 12px 16px;
  border-radius: $borderRadius__button;
  background: white !important;
  font: $font__bodyRegular;
  color: $fbGrey__dark;
  font-size: 16px;
  line-height: 140%;
  border: 1px solid $fbGrey__standard;
  box-shadow: $lightBoxShadow;
  width: 100%;
  outline: none;
  position: relative;

  &::placeholder {
    color: $fbGrey__light !important;
  }

  &.errored {
    border: 1px solid $fbRed__standard;
  }

  &.disabled {
    cursor: not-allowed;
  }

  &:focus {
    outline: 1px auto $fbGreen__light;
  }
}

.LocationSearch__ClearIcon {
  transition: all $navigationTransitionTime;
  position: absolute;
  right: 16px;
  width: 0;
  height: 0;
  color: $fbGrey__light;
  cursor: pointer;

  &:hover {
    color: $fbGrey__standard;
  }

  &.show {
    width: 24px;
    height: 24px;
  }
}

.LocationSearch__LoadingSpinner {
  transition: all $navigationTransitionTime;
  position: absolute;
  right: 48px;
  display: none;
  color: $fbGreen__light;

  &.show {
    display: block;
  }
}

.LocationSearch__dropdown {
  padding: 4px;
  background-color: white;
  overflow: hidden;
  max-height: 200px;
  margin-top: 8px;
  border-radius: $borderRadius__modal;
  border: 1px solid color.adjust($fbGrey__light, $lightness: 15%);
  overflow-y: auto;
  z-index: 99999; // Dropdown needs to be above everything in modal;
  position: absolute;
  width: 100%;

  &.Checkout {
    width: 51%;
  }

  @media (min-width: $md) {
    max-height: 150px;
  }
}

.LocationSearch__dropdownElement {
  font: $font__bodyRegular;
  cursor: pointer;
  background-color: white;
  padding: 8px 12px;
  color: $fbGrey__dark;
  border-radius: $borderRadius__button;

  &.active {
    background-color: $fbGrey__lightest;
    color: $fbGreen__light;
  }
}

.LocationSearch__redirectElements {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
}

.LocationSearch__displayFieldsBtn {
  font: $font__bodyRegular;
  color: $fbGreen__light;
}

.PoweredByGoogleLogo__container {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.ShowManualAddressOveride__ButtonContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 4px;
}

.ButtonLink {
  color: $fbGreen__light;
  text-decoration: underline;
  border: none;
  background-color: inherit;

  &:hover,
  &:focus,
  &:active {
    color: $fbGreen__standard;
    text-decoration: underline;
    outline: none;
  }
}

.InputLabel {
  margin-left: 8px;
  font: $font__bodyRegular;
  font-size: 14px;
  line-height: 140%;
  color: $fbGrey__standard;

  &.error {
    color: $fbRed__standard !important;
  }
}
