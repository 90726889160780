@import '../../stylesheets/variables';

.OrangeLink {
  color: $fbOrange__standard;
  text-decoration: underline;

  &:hover,
  &:focus,
  &:active {
    color: $fbOrange__dark;
    text-decoration: underline;
  }
}
