@import '../variables';

.TextField {
  background: white;
  padding: 0;
  border: 1px solid $fbGrey__standard;
  box-sizing: border-box;
  box-shadow: $lightBoxShadow;
  border-radius: $borderRadius__button;
}

.TextField__label {
  font: $font__bodyRegular;
  color: $fbGrey__dark;
}

.TextField__input {
  font: $font__bodyRegular;
  color: $fbGrey__dark;
  padding: 10px 8px 11px 9px;
}

.TextField__underline {
  &::before,
  &::after {
    content: none;
    display: none;
  }
}

.TextField__focused {
  outline: 5px auto $fbGreen__light;
}
