@import '../variables';

.SearchBar {
  background: white;
  position: relative;
  overflow: hidden;
  border: 1px solid $fbGrey__standard !important;
  box-shadow: $lightBoxShadow;
  border-radius: $borderRadius__button;
}

.SearchBar__ClearIcon {
  transition: all $navigationTransitionTime;
  margin-right: 16px;
  width: 0;
  height: 0;
  color: $fbGrey__light;
  cursor: pointer;

  &:hover {
    color: $fbGrey__standard;
  }

  &.show {
    width: 24px;
    height: 24px;
  }
}

.SearchBar__SearchBtn {
  margin: 0 !important;
  color: $fbGrey__light !important;
  height: 100%;
  border-radius: 0 !important;
  border-top: none !important;
  border-bottom: none !important;
  border-right: none !important;

  &:hover,
  &:active,
  &:focus {
    box-shadow: none !important;
  }

  &:hover {
    background: $fbGreen__standard !important;
    color: white !important;
    border-color: $fbGreen__standard !important;

    svg {
      color: white;
    }
  }
}

.Input__root {
  font: $font__bodyRegular;
  font-size: 18px;
  color: $fbGrey__dark;
}

.Input__input {
  height: 41px;
  box-sizing: unset !important;
  padding: 0 16px;

  /* clears the default 'X' from Internet Explorer */
  &::-ms-clear,
  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  /* clears the default 'X' from Chrome */
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

.SearchBarForm {
  height: 41px;
  display: flex;
  align-items: center;
}
