@import '../../stylesheets/variables';

.LoginFormPaper {
  background: white;
  border-radius: $borderRadius__modal;
  padding: 54px 16px 16px;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: $md) {
    margin: auto;
    padding: 48px;
  }
}

.LoadingWrapper {
  min-height: calc(100vh - 381px);
}

.LoginFormContainer {
  max-width: 490px;
}

.LoginForm__heading {
  font: $font__headingWidget;
  color: $fbGrey__black;
  font-weight: 900;
  font-size: 34px;
  text-transform: uppercase;
  width: 100%;
}

.LoginForm {
  padding-top: 32px;
}

.LoginForm__submitting {
  cursor: progress;
}

.SubHeading {
  color: $fbGreen__light;
}

.LoginForm__formContent {
  display: flex;
  flex-wrap: wrap;
}

.LoginForm__inputs {
  flex-wrap: wrap;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 8px;
  display: flex;
}

.Login__inputContainer {
  width: 100%;
}

.LoginFormSecondaryLinks {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 8px;
}

.SecondaryButton {
  margin: 20px 0 !important;
}

.SubmitBtn {
  margin: 0;
  flex-grow: 1;
  background: $fbGreen__light !important;
  border: none !important;
  color: $fbGrey__white !important;

  &:disabled {
    border: 1px solid $fbGrey__light;
  }

  &:hover {
    box-shadow: $mediumBoxShadow;
    background: $fbGreen__light;
  }

  svg {
    color: $fbGrey__white !important;
    margin: 0 !important;
  }
}

.SubmitBtn__loading {
  color: white;

  svg {
    margin: 0 !important;
  }
}

.Link {
  font: $font__bodyRegular;
  font-size: 14px;
  text-decoration: underline;
  color: $fbGrey__standard;

  &:hover,
  &:focus,
  &:active {
    color: $fbGrey__dark;
  }

  &.Green {
    color: $fbGreen__standard;

    &:hover,
    &:focus,
    &:active {
      color: $fbGreen__dark;
    }
  }

  &.Orange {
    color: $fbOrange__standard;

    &:hover,
    &:focus,
    &:active {
      color: $fbOrange__dark;
    }
  }
}

.PasswordField__endAdornment {
  padding: 8px;
}

.LoginForm__error {
  width: unset;
  max-width: 100%;
}

.Divider {
  width: 100%;
  height: 1px;
  background: $fbGrey__dark;
  position: relative;
  margin: 12px 0 24px;

  span {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $fbGrey__white;
    border-radius: 50%;
    display: grid;
    place-items: center;
  }
}
