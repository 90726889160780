@import '../../stylesheets/variables';

body {
  ::selection {
    background: $fbGreen__lightest;
  }
}

.PublicWebsiteContainer {
  position: relative;
  margin-top: $mobileNavHeight;

  // used $mobileNavHeight and $footerHeight values
  min-height: calc(100vh - 48px - 250px);
  @media (min-width: $md) {
    margin-top: $desktopNavHeight;

    // used $desktopNavHeight and $footerHeight values
    min-height: calc(100vh - 64px - 250px);
  }
}
