@import '../variables';

.Button {
  color: $fbGrey__light;

  &:hover {
    color: $fbOrange__standard;
  }

  &.inactive {
    color: $fbGrey__light;

    &:hover {
      background: transparent !important;
      color: $fbGrey__light;
    }
  }

  &.clicked {
    color: $fbOrange__standard;

    &.inactive {
      color: $fbOrange__standard;

      &:hover {
        background: transparent !important;
        color: $fbOrange__standard;
      }
    }
  }

  &.green {
    &:hover {
      color: $fbGrey__standard;
    }

    &.inactive {
      color: $fbGrey__standard;

      &:hover {
        background: transparent !important;
        color: $fbGrey__standard;
      }
    }

    &.clicked {
      color: $fbGreen__standard;

      &.inactive {
        color: $fbGreen__standard;

        &:hover {
          background: transparent !important;
          color: $fbGreen__standard;
        }
      }
    }
  }

  &.lightGreen {
    &:hover {
      color: $fbGrey__standard;
    }

    &.inactive {
      color: $fbGrey__standard;

      &:hover {
        background: transparent !important;
        color: $fbGrey__standard;
      }
    }

    &.clicked {
      color: $fbGreen__light;

      &.inactive {
        color: $fbGreen__light;

        &:hover {
          background: transparent !important;
          color: $fbGreen__light;
        }
      }
    }
  }
}

.Icon {
  margin-top: -4px;
  margin-bottom: -4px;
}
