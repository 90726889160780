@import '../../../stylesheets/variables';

.CustomerType__ButtonContainer {
  display: flex;
  flex-direction: column;
  margin-top: 34px;
}

.CustomerType__ButtonContainer__addVenue {
  display: flex;
  flex-direction: column;
  width: 95%;
  justify-content: space-evenly;
  padding-top: 30px;

  @media (min-width: $md) {
    flex-direction: row;
    padding-top: 20px;
  }
}

.Type__Button {
  display: flex;
  background-color: transparent;
  padding: 24px;
  margin: 16px 0;
  border: none;
  flex-direction: column;
  box-shadow: $mediumLightBoxShadow;
  border-radius: 12px;
  text-align: left;

  &:hover {
    background-color: $fbGreen__lightest;
  }
}

.Type__title,
.Type__subtitle {
  color: $fbGrey__black;
}

.Type__title {
  font: $font__headingWidget;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 900;
}

.Type__subtitle {
  font: $font__headingRegular;
  font-size: 14px;
  margin-top: 8px;
}

.Type__titleOverride,
.Type__subtitleOverride {
  color: $fbGrey__dark;
}

.Type__titleOverride {
  font-size: 18px;
}

.Type__subtitleOverride {
  font-size: 16px;
}

.AddressTypeImage {
  margin-bottom: 4px;
  width: 74px;
  height: 74px;

  @media (min-width: $md) {
    width: 100px;
    height: 100px;
  }

  @media (min-width: $lg) {
    width: 120px;
    height: 120px;
  }
}

.AddressTypeImage__addVenue {
  margin-bottom: 4px;
  width: 60px;
  height: 60px;
}
