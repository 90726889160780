@import '../../stylesheets/variables';

.ResetPasswordPageContainer {
  min-height: calc(100vh - 414px);
  padding: 32px 8px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  @media (min-width: $md) {
    margin-top: 120px;
    padding: 32px;
  }
}

.ResetPasswordForm {
  padding-top: 32px;
}

.ResetPasswordForm__submitting {
  cursor: progress;
}

.ResetPassword__heading {
  font: $font__headingWidget;
  color: $fbGrey__black;
  font-weight: 900;
  font-size: 34px;
  text-transform: uppercase;
  width: 100%;
}

.SubHeading {
  color: $fbGreen__light;
}

.ResetPasswordForm__formContent {
  display: flex;
  flex-wrap: wrap;
  @media (min-width: $md) {
    flex-direction: column-reverse;
  }
}

.ResetPasswordForm__inputs {
  flex-wrap: wrap;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  @media (min-width: $md) {
    flex-wrap: nowrap;
  }
}

.ResetPasswordForm__inputContainer {
  width: 100%;
}

.SubmitBtnContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: $md) {
    justify-content: flex-end;
  }
}

.SubmitBtn {
  margin: 0;
  flex-grow: 1;
  background: $fbGreen__light !important;
  border: none !important;
  color: $fbGrey__white !important;

  &:disabled {
    border: 1px solid $fbGrey__light;
  }

  &:hover {
    box-shadow: $mediumBoxShadow;
    background: $fbGreen__light;
  }

  svg {
    color: $fbGrey__white !important;
    margin: 0 !important;
  }
}

.SubmitBtn__loading {
  color: white;

  svg {
    margin: 0 !important;
  }
}

.PasswordField__endAdornment {
  padding: 8px;
}

.FakeOrangeLinkBtn {
  outline: none;
  border: none;
  background: transparent;
  margin: 0;
  padding: 0;
  color: $fbOrange__standard;
  text-decoration: underline;

  &:hover,
  &:focus,
  &:active {
    color: $fbOrange__dark;
    text-decoration: underline;
  }
}

.OrangeLink {
  color: $fbOrange__standard;
  text-decoration: underline;

  &:hover,
  &:focus,
  &:active {
    color: $fbOrange__dark;
    text-decoration: underline;
  }
}
