@import '../variables';

.InputContainer {
  width: 100%;
}

.InputLabel {
  margin-left: 8px;
  font: $font__bodyRegular;
  font-size: 14px;
  line-height: 140%;
  color: $fbGrey__standard;

  &.error {
    color: $fbRed__standard !important;
  }
}

.FieldWrapper {
  position: relative;
}

.InputField__endAdornment {
  position: absolute;
  right: 4px;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
}

.InputField {
  box-sizing: border-box;
  padding: 12px 16px;
  border-radius: $borderRadius__button;
  background: white !important;
  font: $font__bodyRegular;
  color: $fbGrey__dark;
  font-size: 16px;
  line-height: 140%;
  border: 1px solid $fbGrey__standard;
  box-shadow: $lightBoxShadow;
  width: 100%;
  outline: none;
  position: relative;

  &::placeholder {
    color: $fbGrey__light !important;
  }

  &.errored {
    border: 1px solid $fbRed__standard;
  }

  &.disabled {
    cursor: not-allowed;
  }

  &:focus {
    outline: 1px auto $fbGreen__light;
  }
}

.ErrorMessageContainer {
  min-height: 24px;
  padding: 4px 8px;
  font: $font__bodyRegular;
  font-size: 12px;
  line-height: 140%;
  color: $fbRed__standard !important;
}

.ErrorIconContainer {
  padding: 8px 4px;
  display: flex;
  align-items: center;
}

.ErrorIcon {
  color: $fbRed__standard;
}
