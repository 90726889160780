/* stylelint-disable no-descending-specificity */
@import '../variables';

.Table {
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  tr {
    th:first-child {
      margin-left: 12px;
    }

    td:first-child {
      margin-left: 12px;
    }

    td:last-child {
      margin-right: 12px;
    }

    th:last-child {
      margin-right: 12px;
    }
  }

  th {
    font: $font__bodyBold;
    color: $fbGrey__dark;
    white-space: nowrap;
    padding: 0 8px;
    background: white;
    font-size: 16px;
    line-height: 145%;
    overflow: hidden;

    &:first-child {
      left: 0;
      top: 0;
      z-index: 2;
      min-width: 116px;
      display: flex;
      align-items: center;
    }
  }

  td {
    font: $font__bodyRegular;
    color: $fbGrey__standard;
    padding: 4px 4px 4px 8px;
    font-size: 16px;
    line-height: 145%;
    background: white;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  td,
  th {
    .TableCell__defaultShow {
      display: flex;
    }

    &.TableCell__showOnHover {
      display: none;
    }
  }

  &.stickyFirstColumn {
    th {
      &:first-child {
        position: sticky;
      }
    }
  }
}

.RightAlign {
  text-align: right;
  justify-content: flex-end;
  margin-right: -8px;
}

.Table__contentContainer {
  max-width: 100vw;
  background: $fbGrey__lightest;
}

.Table__content {
  max-width: $maxPageWidth;
  margin: auto;
  padding: 20px 0;
  @media (min-width: $md) {
    padding: 20px 0;
  }
  @media (min-width: $lg) {
    padding: 20px 32px;
  }

  &.noPadding {
    padding: 0;
  }

  &.grow {
    padding: 0;
    max-width: fit-content;
  }
}

.Font__standardGrey {
  color: $fbGrey__standard;
}

.TableContainer {
  position: relative;
  max-width: 100%;
  margin: auto;
  overflow-y: auto !important;
  padding-left: 12px;
  padding-right: 12px;
}

.LoadingTablePaperWrapper {
  padding: 0;
}

.TablePaperWrapper {
  padding: 0 !important;
  @media (min-width: $lg) {
    padding: 12px 0 !important;
  }
}

.SortByIcon {
  margin-left: 4px;
  color: $fbGrey__light;
}

.MobileWhiteBackgroundFaker {
  background: white;
  z-index: 5;
  height: 56px;
  display: flex;
  align-items: center;

  &.RightAlign {
    text-align: right;
    justify-content: flex-end;
    margin-right: -8px;
  }
}

.TableHeaderContainer {
  display: inline-flex;
  border: none;
  background: none;
  outline: none;
  border-radius: $borderRadius__modal;
  margin-left: -8px;
  align-items: center;
  padding: 8px;
  font: $font__bodyBold !important;
  color: $fbGrey__dark;
  letter-spacing: 0.05em;
  line-height: 140%;
  text-transform: none !important;
  font-variant: normal !important;
  @media (min-width: $md) {
    line-height: 140%;
  }

  &.Clickable {
    cursor: pointer;

    &.active,
    &:hover {
      background: $fbGrey__lightest;

      .SortByIcon {
        color: $fbGreen__light;
      }
    }
  }
}

.Table__row {
  position: relative;
  border-bottom: 1px solid $fbGrey__lightest;
  background: white;
  display: flex;
  align-items: center;
  align-content: center;

  &.bodyRow {
    &:hover {
      border-radius: $borderRadius__modal;
      background: $fbGrey__lightest;

      .MobileWhiteBackgroundFaker {
        background: $fbGrey__lightest;
      }

      td,
      th {
        background: $fbGrey__lightest;
        color: $fbGrey__dark;
      }

      .TableCell__defaultShow {
        display: none;
      }

      .TableCell__showOnHover {
        display: flex;
      }
    }

    .TableCell__showOnHover {
      display: none;
    }
  }
}

.MobileArrowContainer {
  z-index: 20;
  background: $fbGreen__lightest;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: fixed;
  right: -50px;
  top: calc(50% - 50px);
  opacity: 0.9;
  box-shadow: $darkBoxShadow;
  color: $fbGreen__dark;
  display: flex;
  align-items: center;
  margin-left: 22px;
  cursor: pointer;
  outline: none;
  border: none;
  transition: all 650ms cubic-bezier(0.25, 1.7, 0.35, 0.8);

  &:hover {
    box-shadow: -2px 2px 8px $fbGrey__standard;
  }
  @media (min-width: $lg) {
    display: none;
  }
}

.ReversibleArrow {
  position: relative;
  left: 0;
  top: -6px;

  .BottomBar {
    position: absolute;
    background-color: transparent;
    top: 4px;
    left: 5px;
    width: 5px;
    height: 24px;
    display: block;
    transform: rotate(35deg);
    transform: rotate(45deg);
    border-radius: 2px;

    &::after {
      content: '';
      background-color: $fbGreen__dark;
      width: 24px;
      height: 5px;
      display: block;
      transition: all 650ms cubic-bezier(0.25, 1.7, 0.35, 0.8);
      z-index: -1;
      border-radius: 10px 6px 6px 10px;
    }
  }

  .TopBar {
    position: absolute;
    background-color: transparent;
    left: 9px;
    width: 24px;
    height: 5px;
    display: block;
    transform: rotate(-45deg);
    border-radius: 2px;

    &::after {
      content: '';
      background-color: $fbGreen__dark;
      width: 24px;
      height: 5px;
      display: block;
      transition: all 650ms cubic-bezier(0.25, 1.7, 0.35, 0.8);
      border-radius: 10px 6px 6px 10px;
    }
  }

  &.reverse {
    left: 2px;

    .TopBar {
      &::after {
        transform-origin: center center;
        transform: rotate(-90deg);
      }
    }

    .BottomBar {
      &::after {
        transform-origin: center center;
        transform: rotate(90deg);
      }
    }
  }
}

.Hide {
  display: none;
}

.TypographySection {
  padding-top: 12px;
  padding-bottom: 12px;
}

.TypographyBody {
  margin-bottom: 8px;
}

.NoResultsBtn {
  margin: auto;
}

.HideDesktop {
  display: block;
  @media (min-width: $md) {
    display: none;
  }
}

.HideMobile {
  display: none;
  @media (min-width: $md) {
    display: block;
  }
}

.ElementsCountContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 24px;
  padding-bottom: 8px;
}

.PaginationButtonsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 8px;
  padding-bottom: 12px;
}

.PaginationBtn {
  margin: 0 16px;
}

.TableFlexContainer {
  display: flex;
  align-items: center;
}

.MobileBoxShadowFaker {
  z-index: 2;
  height: 56px;
  width: 100%;
  box-shadow: -8px 0 18px 0 $fbGrey__standard;
  @media (min-width: $lg) {
    display: none;
  }
}

.Grow {
  flex-grow: 1;
}
