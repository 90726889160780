@import '../variables';

.InnerContent {
  padding: 30px;
}

.Tooltip__tooltip {
  box-shadow: 0 2px 8px #717770;
  background: $fbGreen__dark;
  font: $font__bodyRegular;
  line-height: 22px;
  text-align: center;
  color: white;
  padding: 16px;
}

.Tooltip__arrow {
  color: $fbGreen__dark;
}

.Tooltip__popper {
  z-index: 6005 !important;
}
