@import '../../stylesheets/variables';

.SignUp__Form__Container {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;

  @media (min-width: $md) {
    flex-direction: row;
    align-items: center;
  }
}

.Image__Container {
  flex: 0.6;
  align-items: center;
  height: 90vh;
}

.Image__Wrapper {
  display: flex;
  flex-direction: column;
  margin: 60px auto;
  max-width: 600px;
}

.SignUp__Image {
  margin-bottom: 0 auto 30px;

  div {
    max-width: 300px;
    margin-left: 50px;
  }
}

.Image {
  display: block;
  width: 100%;
  height: 100%;
  margin: 20px auto;
}

.Image__Content {
  margin-top: 10px;
  margin-left: 50px;
}

.Icon {
  color: $fbGreen__light;
  margin-right: 10px;
}

.Image__Details {
  display: flex;
  align-items: center;
  margin: 15px 0;
  max-width: 250px;
}

.Form__Container {
  background-color: $fbGrey__lighter;
  flex: 0.5;
  height: 100vh;
}

.Form__Content {
  background-color: $fbGrey__white;
  border-radius: $borderRadius__modal;
  max-width: 500px;
  max-height: 800px;
  margin: 60px auto 20px;
  padding: 30px 40px;
}

.Form__header {
  margin-bottom: 15px;
}

.SignUp__Details__Form__Section {
  padding: 8px 0;
}

.Input__fullWidth {
  width: 100%;
  margin: 0 auto;
}

.Input__fullWidth__Email {
  width: 100%;
  margin: 0 auto;

  input {
    background: $fbGrey__lighter !important;
  }
}

.PasswordField__endAdornment {
  padding: 8px;
}

.Submit__Btn__Container {
  margin-top: 12px;
  width: 100%;
}

.Submit__Btn {
  margin: 0 auto;
  width: 100%;

  &:disabled {
    background: $fbGrey__lightest;
    color: $fbGrey__standard;
    border: 1px solid $fbGrey__light;

    &:hover {
      border: 1px solid $fbGrey__light;
      background: $fbGrey__lightest;
      color: $fbGrey__standard;
    }
  }
}

.SubmitBtn__loading {
  color: $fbGreen__standard;

  svg {
    margin: 0 !important;
  }
}
