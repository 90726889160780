@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');

$desktopNavHeight: 64px;
$mobileNavHeight: 48px;
$dashboardNavigationHeight: 44px;
$combinedAuthenticatedNavHeight: 108px;
$footerHeight: 250px;
$maxPageWidth: 1060px;
$font__bodyRegular: 400 16px 'Source Sans Pro';
$font__bodyBold: 700 16px 'Source Sans Pro';
$font__headingLight: 300 12px 'Montserrat';
$font__headingRegular: 400 12px 'Montserrat';
$font__headingMedium: 500 12px 'Montserrat';
$font__headingSemiBold: 600 12px 'Montserrat';
$font__headingBold: 700 12px 'Montserrat';

// ------ Transition Times ------
$navigationTransitionTime: 200ms;

// ------ Borders ------
$borderRadius__button: 8px;
$borderRadius__modal: 8px;

// ------ Breakpoints ------
$xs: 320px;
$sm: 480px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

// ------ New Palette ------

// Greys
$fbGrey__dark: #485146;
$fbGrey__standard: #6e7370;
$fbGrey__light: #bbb;
$fbGrey__lighter: #e7e7e7;
$fbGrey__lightest: #f7f7f7;
$fbGrey__white: #fff;

// Oranges
$fbOrange__dark: #965100;
$fbOrange__standard: #ff8a00;
$fbOrange__light: #ffb661;
$fbOrange__lightest: #fff4ec;

// Greens
$fbGreen__dark: #006b33;
$fbGreen__standard: #009447;
$fbGreen__light: #00c65f;
$fbGreen__lightest: #edfff6;
$fbGreen__transparent: rgb(0 149 100 / 8%);

// Reds
$fbRed__dark: #8b0000;
$fbRed__standard: #d32f2f;
$fbRed__light: #ff6261;
$fbRed__lightest: #fff4f4;

// Yellow
$fbYellow__dark: #775f00;
$fbYellow__standard: #bf9900;
$fbYellow__light: #fc0;
$fbYellow__lightest: #fff9e2;

// Blues
$fbBlue__lightest: #eaf9ff;
$fbBlue__light: #31c1ff;
$fbBlue__standard: #07a;
$fbBlue__dark: #004a6a;
$fbTiel__standard: #14ab9a;

// ------ Shadows ------
$lightBoxShadow: 0 0 8px $fbGrey__lightest;
$darkBoxShadow: 0 2px 8px $fbGrey__standard;
