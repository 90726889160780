@import '../variables';

.FullWidthNotification {
  max-width: 100%;
  width: 100%;
}

.ErrorNotification__content {
  padding-top: 4px;
  overflow-wrap: break-word;
}

.ErrorNotification__actions {
  margin-top: 12px;
  display: flex;
}

.RedLink {
  color: $fbRed__dark;
  text-decoration: underline;

  &:hover,
  &:focus,
  &:active {
    color: $fbRed__dark;
    text-decoration: underline;
  }
}

.ContactInfo {
  color: inherit;
  margin-top: 8px;
}

.ErrorBody {
  color: inherit;
}
