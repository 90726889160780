@import '../variables';

.MenuRoot {
  z-index: 2000 !important;
}

.MenuPaper {
  margin-left: 2px;
  padding: 8px;
  border-radius: $borderRadius__button;
  border: 1px solid $fbGrey__lightest;
  box-sizing: border-box;
  box-shadow: 0 2px 8px #717770;
}
