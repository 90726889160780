@import '../../stylesheets/variables';

.FooterContainer {
  min-height: $footerHeight;
  background: $fbGrey__black;
  color: $fbGrey__white;
}

.FooterContent {
  width: 100%;
  flex: 1;
  height: 100%;
  max-width: $maxPageWidth;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  @media (min-width: $md) {
    flex-wrap: nowrap;
  }
}

.FooterLogo__wrapper {
  width: 100%;
  padding: 20px 0;
  height: 70px;
  margin-bottom: 24px;
}

.FooterLogo {
  height: 45px;
  margin-left: -8px;
}

.FooterLinks__Section {
  display: flex;
  flex-flow: column wrap;
  align-items: baseline;
  gap: 20px;
  margin: 16px 0;

  @media (min-width: $md) {
    flex-flow: row;
  }
}

.FooterLinks__columnWrapper {
  flex: 1;
}

.FooterLinks__title {
  font: $font__bodyBoldLarge;
  color: $fbGrey__white;
  font-size: 25px;
  margin-bottom: 24px;
  text-transform: uppercase;
}

.FooterLink {
  display: block;
  font: $font__bodyBoldLarge;
  text-transform: uppercase;
  font-size: 20px;
  color: $fbGrey__white;
  padding-top: 4px;
  text-decoration: none;
  transition: $navigationTransitionTime;
  margin: 8px 0;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: $fbGrey__light;
  }

  &:focus {
    outline: 5px auto $fbGreen__light;
  }
}

.FooterLinks__socialColumnWrapper {
  flex: 0.45;
}

.FooterLinks__socialLinks {
  display: flex;
  gap: 40px;
  flex: 0.44;
}

.FooterLinks__socialColumn {
  display: flex;
  align-items: baseline;
  gap: 20px;
}

.FooterLink__socialIcons {
  flex: 0.5;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $fbGrey__white;
  border-radius: 50%;
  margin-right: 12px;
}

.SocialIcon {
  color: $fbGrey__black;
}

.FooterLinks {
  text-align: center;
  padding: 24px 24px 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  @media (min-width: $md) {
    text-align: left;
    width: unset;
    flex-wrap: nowrap;
    flex: 0.6;
  }
}

.FooterBottom__section {
  margin: 16px 0;
  color: $fbGrey__white;
}

.FooterBottom__textWrapper {
  margin: 24px 0;
}

.FooterBottom__text {
  color: $fbGrey__white;
}

.FooterBottom__textTitle {
  color: $fbGrey__white;
  text-transform: uppercase;
  font: $font__bodyBoldLarge;
  margin-bottom: 16px;
}
