@import '../variables';

.AlertDialogRoot {
  z-index: 6000 !important;
}

.AlertDialogPaper {
  margin: 20px !important;
  min-width: 90vw;
  border: 2px solid $fbGrey__lightest;
  border-radius: $borderRadius__modal;
  @media (min-width: $sm) {
    min-width: 500px;
  }
  @media (min-width: $md) {
    margin: inherit;
  }
}

.AlertDialog__header {
  background: white;
  border-radius: $borderRadius__modal $borderRadius__modal 0 0;
  color: $fbGrey__dark;
}

.AlertDialog__icon {
  text-align: center;
  padding: 12px 0;
}

.AlertDialog__closeBtn {
  position: absolute;
  right: 0;
  top: 0;
}

.AlertDialog__heading {
  font: $font__bodyBold;
  font-size: 26px;
  text-align: center;
  padding-bottom: 10px;
}

.AlertDialog__message {
  text-align: center;
  font: $font__bodyRegular;
  font-size: 18px;
  color: $fbGrey__standard;
  padding: 16px 0;

  span {
    font: $font__bodyBold;
    font-size: 18px;
  }
}

.AlertDialog__actions {
  display: flex;
  justify-content: center;
  text-align: center;
}
