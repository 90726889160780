@import '../../stylesheets/variables';

.LogoContainer {
  width: 100%;
  max-width: $maxPageWidth;
  margin: auto;
}

.NavigationOffset {
  color: $fbGrey__standard;
  background: white;
  height: $mobileNavHeight;
  padding-left: 8px;
  padding-right: 8px;
  @media (min-width: $md) {
    height: $desktopNavHeight;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.NavigationLogo {
  height: 32px;
  @media (min-width: 375px) {
    height: 36px;
  }
  @media (min-width: $md) {
    height: 45px;
  }
}

.HeaderContainer {
  background: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.HeaderContent {
  width: 100%;
  padding: 20px 8px;
  max-width: $maxPageWidth;
  margin: auto;
  @media (min-width: $md) {
    padding: 20px 16px;
  }
}

.PageContent {
  max-width: $maxPageWidth;
  padding: 20px 8px;
  @media (min-width: $md) {
    padding: 20px 16px;
  }

  margin: auto;
}

.Font__standardGrey {
  color: $fbGrey__standard;
}

.GameTitle {
  margin-bottom: 30px;
}

.ResetButtonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}
