/* stylelint-disable no-descending-specificity */

@import '../variables';

.ToggleButton {
  min-height: 44px;

  &:hover {
    .Switch__switchBase {
      &.checked {
        color: $fbGreen__standard;
      }
    }

    .Switch__track {
      &.checked {
        border: 2px solid $fbGreen__standard;
      }
    }
  }
}

.Switch__formControl {
  margin-left: 8px;
  margin-right: 8px;

  &.horizontal {
    width: 90px;
  }
}

.Switch__label {
  font: $font__headingBold;
  font-size: 16px;
  color: $fbGrey__dark;
  line-height: 140%;
  margin-bottom: 4px;

  &.horizontal {
    margin-bottom: 0;
    margin-left: 8px;
  }
}

.Switch__root {
  width: 36px;
  height: 20px;
  display: flex;
  align-items: center;
  padding: 1px;
  margin-left: 8px;
}

.Switch__switchBase {
  padding: 0;
  margin-left: 5px;
  margin-top: 4px;
  color: $fbGrey__standard;

  &.checked {
    transform: translateX(14px);
    color: $fbGreen__standard;
  }
}

.Switch__thumb {
  width: 12px;
  height: 12px;
  box-shadow: none;
}

.Switch__track {
  border-radius: 8px;
  opacity: 1;
  background: transparent;
  border: 2px solid $fbGrey__standard;

  &.checked {
    opacity: 1 !important;
    background: transparent !important;
    border: 2px solid $fbGreen__standard;
  }
}
