@import '../variables';

.ContainerBox {
  width: 100%;
  overflow: hidden;
  padding: 18px;
  padding-right: 0;

  &.RemovePadding {
    padding: 0;
  }
}

.Header__textContainer {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  @media (min-width: $md) {
    width: unset;
  }
}

.Header__titleRow {
  display: flex;
  align-items: center;
}

.Header__subheading {
  display: flex;
  margin-top: 8px;
}

.MainSliderContainer {
  position: relative;
  padding-top: 8px;
  margin-left: -4px;
  justify-content: flex-end;
  max-height: 600px;
  transition: all $navigationTransitionTime;

  &.Hide {
    max-height: 0;
    padding-top: 0;
  }
}

.SliderContainer {
  display: flex;
  overflow-x: auto;
  margin: auto;
  @media (min-width: $md) {
    margin: 0;
  }

  &::after {
    display: block;
    content: '';
    min-width: 8px;
    background: transparent;
    height: auto;
    @media (min-width: $md) {
      min-width: 16px;
    }
  }
}

.ScrollArrowContainer {
  z-index: 99;
  background: $fbGreen__lightest;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: absolute;
  right: -52px;
  top: calc(50% - 55px);
  opacity: 0.9;
  box-shadow: $darkBoxShadow;
  color: $fbGreen__dark;
  display: flex;
  align-items: center;
  margin-left: 22px;
  cursor: pointer;
  outline: none;
  border: none;
  transition: all 650ms cubic-bezier(0.25, 1.7, 0.35, 0.8);

  &:hover {
    box-shadow: -2px 2px 8px $fbGrey__standard;
  }
}

.ReversibleArrow {
  position: relative;
  left: 0;
  top: -6px;

  .BottomBar {
    position: absolute;
    background-color: transparent;
    top: 4px;
    left: 5px;
    width: 5px;
    height: 24px;
    display: block;
    transform: rotate(35deg);
    transform: rotate(45deg);
    border-radius: 2px;

    &::after {
      content: '';
      background-color: $fbGreen__dark;
      width: 24px;
      height: 5px;
      display: block;
      transition: all 650ms cubic-bezier(0.25, 1.7, 0.35, 0.8);
      z-index: -1;
      border-radius: 10px 6px 6px 10px;
    }
  }

  .TopBar {
    position: absolute;
    background-color: transparent;
    left: 9px;
    width: 24px;
    height: 5px;
    display: block;
    transform: rotate(-45deg);
    border-radius: 2px;

    &::after {
      content: '';
      background-color: $fbGreen__dark;
      width: 24px;
      height: 5px;
      display: block;
      transition: all 650ms cubic-bezier(0.25, 1.7, 0.35, 0.8);
      border-radius: 10px 6px 6px 10px;
    }
  }

  &.reverse {
    left: 2px;

    .TopBar {
      &::after {
        transform-origin: center center;
        transform: rotate(-90deg);
      }
    }

    .BottomBar {
      &::after {
        transform-origin: center center;
        transform: rotate(90deg);
      }
    }
  }
}

.ContentLoader {
  padding-top: 12px;
  overflow: hidden;
}

.HideButton {
  margin: 0;
  margin-left: 8px;
}
