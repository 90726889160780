@import '../variables';

.FBRadio {
  &:hover {
    background-color: $fbGreen__transparent !important;
  }
}

.FBRadio__checked {
  color: $fbGreen__standard !important;

  &:hover {
    background-color: $fbGreen__transparent !important;
  }
}
