@import '../variables';

.NoResultsContainer {
  text-align: center;
  margin: auto;
  margin: 16px;
  padding: 12px;
  @media (min-width: $sm) {
    margin: 22px 12px;
    padding: 25px;
  }
}

.TypographySection {
  padding-top: 12px;
  padding-bottom: 12px;
}

.NoResultsBtn {
  margin: auto;
}

.NoResultsBtnContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
