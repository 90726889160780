@import '../variables';

.MobileFiltersDrawer__paper {
  width: 264px;
  background: $fbGrey__lightest;
  height: calc(100vh - 48px);
  margin-top: $mobileNavHeight;
  overflow-x: hidden;
  @media (min-width: $md) {
    margin-top: $combinedAuthenticatedNavHeight;
    height: calc(100vh - 64px);
  }
}
