.FilterButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.FilterButton__filterIcon {
  margin-right: 8px;
}

.FilterButton__badge {
  margin-left: 16px !important;
}
