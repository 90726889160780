@import '../variables';

.Button {
  color: $fbGrey__light;

  &:hover {
    color: $fbBlue__light;
  }

  &.inactive {
    color: $fbGrey__light;

    &:hover {
      background: transparent !important;
      color: $fbGrey__light;
    }
  }

  &.clicked {
    color: $fbBlue__light;

    &.inactive {
      color: $fbBlue__light;

      &:hover {
        background: transparent !important;
        color: $fbBlue__light;
      }
    }
  }
}
