@import '../../../stylesheets/variables';

.VenueDetailsForm__header {
  display: flex;
  flex-direction: column;
}

.VenueDetailsForm__title {
  font: $font__headingWidget;
  font-size: 34px;
  text-transform: uppercase;
  font-weight: 900;
  color: $fbGrey__black;
}

.VenueDetailsFormHeader__left {
  flex: 0.7;
}

.VenueDetailsFormHeader__right {
  display: none;
  @media (min-width: $md) {
    display: block;
    flex: 0.3;
    text-align: right;
  }
}

.GreenSubHeading {
  color: $fbGreen__light;
}

.OrangeLink {
  color: $fbOrange__standard;
  text-decoration: underline;

  &:hover,
  &:focus,
  &:active {
    color: $fbOrange__dark;
    text-decoration: underline;
  }
}

.OrangeButtonLink {
  border: none;
  outline: none;
  background: none;
  margin: 0;
  padding: 0;
  color: $fbOrange__standard;
  text-decoration: underline;

  &:hover,
  &:focus,
  &:active {
    color: $fbOrange__dark;
    text-decoration: underline;
  }
}

.SupplierRedirectNotificationButton {
  border: none;
  outline: none;
  background: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.VenueDetailsFormSection {
  padding-top: 16px;
  padding-bottom: 16px;
}

.VenueDetailsFormSection__heading {
  padding-bottom: 12px;
}

.InputRow {
  flex-wrap: wrap;
  width: 100%;
  display: flex;
}

.SingleSelectWrapper {
  width: 100%;
}

.Input__singleSelect {
  width: 100% !important;
  border-radius: 20px !important;

  div {
    border-radius: 8px !important;
  }
}

.RadioInputRow {
  width: 100%;
  max-width: 399px;
  margin: auto;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
}

.Input__fullWidth {
  width: 100%;
}

.InputLabel {
  font: $font__headingRegular;
  font-size: 13px;
}

.PasswordField__endAdornment {
  padding: 8px;
}

.PostcodeField__endAdornment {
  color: $fbGreen__light;
  margin-right: 8px;
}

.SubmitBtnContainer {
  margin-top: 12px;
  width: 100%;
}

.SubmitBtn {
  margin: 0;
  width: 100%;
  background: $fbGreen__light !important;
  border: none !important;
  color: $fbGrey__white !important;

  &:disabled {
    background: $fbGrey__lightest;
    color: $fbGrey__standard;
    border: 1px solid $fbGrey__light;
  }

  &:hover {
    &:hover {
      border: 1px solid $fbGrey__light;
      background: $fbGrey__lightest;
      color: $fbGrey__standard;
    }
  }

  svg {
    margin: 0 !important;
    color: $fbGrey__white;
  }
}

.RadioFieldOverride {
  appearance: none;
  visibility: hidden;
  margin-right: 32px;

  &:last-child {
    margin-right: 0;
  }
}

.RadioTagBtn {
  font: $font__bodyRegular;
  font-size: 14px;
  line-height: 140%;
  padding: 12px 8px;
  background: white;
  border-radius: $borderRadius__modal;
  box-shadow: $lightBoxShadow;
  border: 1px solid $fbGrey__lightest;
  color: $fbGrey__light;
  width: 100%;
}

.RadioImageBtn {
  width: 64px;
  height: 64px;
  background: white;
  border-radius: $borderRadius__modal;
  box-shadow: $lightBoxShadow;
  border: 1px solid $fbGrey__lightest;
  color: $fbGrey__light;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 48px;
    height: 48px;
  }
}

.RadioFieldOverride__label {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 108px;
  padding-bottom: 12px;
  cursor: pointer;

  &.selected,
  &:hover {
    .RadioImageBtn,
    .RadioTagBtn {
      background: $fbGreen__lightest;
      border: 1px solid $fbGreen__dark;
      color: $fbGreen__dark;
    }
  }
}

.RadioImageBtn__label {
  margin-top: 8px;
  font: $font__bodyRegular;
  color: $fbGrey__dark;
  font-size: 14px;
  line-height: 140%;
}

.ErrorMessageContainer {
  padding: 4px 8px;
  font: $font__bodyRegular;
  font-size: 12px;
  line-height: 140%;
  color: $fbRed__standard !important;
}

.Checkbox__Label {
  font: $font__headingRegular;
  font-size: 13px;
  color: $fbGrey__standard;
}

.Checkbox__root {
  color: $fbGreen__light !important;

  &:hover {
    background-color: $fbGreen__transparent !important;
  }
}

.Checkbox__checked {
  color: $fbGreen__light !important;

  &:hover {
    background-color: $fbGreen__transparent !important;
  }
}

.LegalCheckbox__container {
  display: flex;
  align-items: center;
}

.LegalCheckbox__errors {
  display: block;
  color: $fbRed__standard;
  margin-left: 8px;
  padding-bottom: 12px;
  font: $font__bodyRegular;
  font-size: 14px;
  line-height: 140%;
}

.ButtonLink {
  color: $fbGreen__light;
  text-decoration: underline;
  border: none;
  background-color: inherit;
  padding: 0;
  display: inline-block;

  &:hover,
  &:focus,
  &:active {
    color: $fbGreen__standard;
    text-decoration: underline;
    outline: none;
  }
}

.GroupCheckbox__container {
  display: flex;
  align-items: center;
}

.EmailError {
  margin-top: -21px;
  margin-bottom: 12px;
  font-size: 12px;
  color: $fbRed__standard;
  margin-left: 202px;
}

.ResetPassword__link {
  color: $fbRed__standard;
  text-decoration: underline;
  transition: all $navigationTransitionTime;

  &:hover {
    color: $fbRed__light;
  }
}
