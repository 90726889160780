@import '../../../stylesheets/variables';

.DrawToggle {
  transition: all $navigationTransitionTime;
  box-sizing: border-box;
  cursor: pointer;
  z-index: 99;
  width: 19px;

  @media (min-width: $md) {
    display: none;
  }

  &.open {
    .spinner1 {
      transform: rotate(135deg);
      margin-top: 6.4px;
    }

    .spinner2 {
      opacity: 0;
    }

    .spinner3 {
      transform: rotate(-135deg);
      margin-top: -7.2px;
    }
  }
}

.DrawToggle__spinner {
  height: 2px;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  background-color: $fbGrey__standard;
  transition: all $navigationTransitionTime;
  float: left;

  &.spinner1 {
    margin-top: 0;
  }

  &.spinner2 {
    margin-top: 3px;
  }

  &.spinner3 {
    margin-top: 3px;
  }
}

.Navigation__AppBar {
  color: $fbGrey__standard;
  background: white;
  height: $mobileNavHeight;
  padding-left: 8px;
  padding-right: 8px;
  transition: $navigationTransitionTime;
  box-shadow: $lightBoxShadow;
  border-bottom: 1px solid $fbGrey__lightest;

  &.expanded {
    height: 118px;
  }
  @media (min-width: $md) {
    height: $desktopNavHeight;
    padding-left: 16px;
    padding-right: 16px;

    &.noShadow {
      box-shadow: none;
      border-bottom: 1px solid white;
    }

    &.expanded {
      height: $desktopNavHeight;
    }
  }
}

.MobileDrawerContainer {
  width: 260px;
  margin-top: $mobileNavHeight;
  padding: 4px 12px 120px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  @media (min-width: $md) {
    margin-top: $combinedAuthenticatedNavHeight;
  }
}

.MobileDrawerButton {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.MobileDrawerDivider {
  margin-top: 30px;
  margin-bottom: 20px;
}

.Link {
  font: $font__bodyRegular;
  font-size: 18px;
  color: $fbGrey__standard !important;
  cursor: pointer !important;
  background: white;
  border: none;
  padding: 0;

  &:focus {
    color: $fbGrey__standard;
    text-decoration: none !important;
    outline: none;
  }

  &:hover,
  &:active {
    color: $fbGreen__light !important;
    text-decoration: none !important;

    .MinimalText {
      color: $fbGreen__light;
    }
  }

  &.active {
    color: $fbGreen__light !important;
    text-decoration: none !important;

    .MinimalText {
      color: $fbGreen__light;
    }

    &:hover,
    &:active,
    &:focus {
      color: $fbGreen__light !important;
      text-decoration: none !important;

      .MinimalText {
        color: $fbGreen__light;
      }
    }
  }

  &.disabled {
    opacity: 0.5 !important;
    cursor: not-allowed !important;
  }

  &.hideOnTablet {
    display: none;
    @media (min-width: $lg) {
      display: block;
    }
  }
}

.Link__Active {
  color: $fbGreen__standard;

  &:hover,
  &:focus {
    color: $fbGreen__standard;
    text-decoration: none;
  }
}

.AppBar__MobileLinkItem {
  padding: 14px 6px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: $fbGrey__standard;

  &:hover {
    color: $fbGrey__dark;

    .MobileLinkItem__icon {
      padding-right: 12px;
      color: $fbGreen__light;
    }
  }
}

.AppBar__MobileLinkList {
  list-style: none;
  padding: 0;
  margin-top: 4px;
  margin-bottom: 4px;
}

.AppBar__logo {
  height: 32px;
  transition: all $navigationTransitionTime;
  padding-right: 0;
  @media (min-width: 375px) {
    height: 36px;
  }
  @media (min-width: $md) {
    height: 45px;
  }

  &.SupplierHeaderLogo {
    height: 44px;
    padding-right: 20px;
    @media (min-width: $md) {
      height: 55px;
    }
  }
}

.AppBar__Content {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  max-width: $maxPageWidth;
  margin: auto;
}

.AppBar__DesktopContent {
  display: none;
  @media (min-width: $md) {
    display: flex;
    padding-left: 24px;
    padding-right: 24px;
  }
}

.AppBar__MobileContent {
  display: flex;
  @media (min-width: $md) {
    display: none;
  }
}

.AppBar__Left {
  width: 25%;
  display: flex;
  @media (min-width: $md) {
    width: auto;
  }
}

.AppBar__Center {
  flex-grow: 1;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  @media (min-width: $lg) {
    padding-left: 25px;
    padding-right: 20px;
  }
}

.AppBar__Right {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (min-width: $md) {
    width: auto;
  }
}

.LogoBtn {
  display: flex;
  align-items: center;
}

.AppBar__ButtonContainer {
  display: flex;
  margin-left: 8px;
}

.BookACall__button {
  background: $fbGreen__light !important;
  border: none !important;
  color: $fbGrey__white !important;
}

.BookACall__buttonText {
  &.Tablet {
    display: block;
    @media (min-width: $lg) {
      display: none;
    }
  }

  &.Desktop {
    display: none;
    @media (min-width: $lg) {
      display: block;
    }
  }
}

.AppBar__Icon {
  font: $font__bodyRegular;
  color: $fbGrey__standard;

  &.light {
    color: $fbGrey__light;
  }

  &:hover,
  &:active,
  &:focus {
    color: $fbGreen__light;
    text-decoration: none;
  }
}
