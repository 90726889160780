@import '../../stylesheets/variables';

.SignUpPageContainer {
  display: flex;
  justify-content: center;
  background: $fbGrey__white;
  min-height: calc(100vh - 48px);
  @media (min-width: $md) {
    min-height: calc(100vh - 64px);
  }
}

.HideOnMobile {
  display: none;
  @media (min-width: $md) {
    display: block;
  }
}

.EmbeddedFrame {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
}
