@import '../variables';

.ChipIcon {
  color: $fbGreen__standard;

  &:hover {
    color: $fbGreen__dark;
  }
}

.Chip {
  margin: 4px;
  border-radius: $borderRadius__button;
  background: $fbGreen__lightest;
  color: $fbGreen__dark;
  border: 1px solid $fbGreen__standard;
  font: $font__bodyRegular;
  line-height: 140%;
  font-size: 16px;
  letter-spacing: 0.03em;
  text-transform: none !important;
  font-variant: normal !important;

  &.clickable {
    cursor: pointer;

    &:hover {
      cursor: pointer;
      background: $fbGreen__standard;
      color: white;

      .ChipIcon {
        color: white;
        color: $fbGrey__lightest;
      }
    }
  }
}
