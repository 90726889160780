@import '../variables';

.Typography {
  color: $fbGrey__dark;
  font: $font__bodyRegular;
  line-height: 140%;
}

.Heading_xxxl {
  font: $font__headingBold;
  font-size: 30px;
  color: $fbGrey__dark;
  margin-bottom: 12px;
  line-height: 140%;
  @media (min-width: $md) {
    font-size: 40px;
    margin-bottom: 16px;
    line-height: 140%;
  }
  @media (min-width: $lg) {
    font-size: 48px;
    margin-bottom: 16px;
    line-height: 140%;
  }
}

.Heading_xxl {
  font: $font__headingBold;
  font-size: 26px;
  color: $fbGrey__dark;
  margin-bottom: 12px;
  line-height: 140%;
  @media (min-width: $md) {
    font-size: 32px;
    margin-bottom: 16px;
    line-height: 140%;
  }
  @media (min-width: $lg) {
    font-size: 36px;
    margin-bottom: 12px;
    line-height: 140%;
  }
}

.Heading_xl {
  font: $font__headingBold;
  font-size: 22px;
  color: $fbGrey__dark;
  line-height: 140%;
  @media (min-width: $md) {
    font-size: 26px;
    line-height: 140%;
  }
  @media (min-width: $lg) {
    font-size: 26px;
    line-height: 140%;
  }
}

.Heading_l {
  font: $font__headingBold;
  font-size: 18px;
  color: $fbGrey__dark;
  line-height: 140%;
  @media (min-width: $md) {
    font-size: 22px;
    line-height: 140%;
  }
  @media (min-width: $lg) {
    font-size: 22px;
    line-height: 140%;
  }
}

.Heading_m {
  font: $font__headingBold;
  font-size: 16px;
  color: $fbGrey__dark;
  margin-bottom: 8px;
  line-height: 140%;
  @media (min-width: $md) {
    font-size: 18px;
    margin-bottom: 8px;
    line-height: 140%;
  }
  @media (min-width: $lg) {
    font-size: 18px;
    margin-bottom: 8px;
    line-height: 140%;
  }
}

.Heading_s {
  font: $font__headingSemiBold;
  font-size: 14px;
  color: $fbGrey__dark;
  letter-spacing: 0.05em;
  line-height: 140%;
  @media (min-width: $md) {
    font-size: 16.5px;
    line-height: 140%;
  }
  @media (min-width: $lg) {
    font-size: 16.5px;
    line-height: 140%;
  }
}

.Body_l {
  color: $fbGrey__dark;
  font: $font__bodyRegular;
  font-size: 18px;
  line-height: 140%;
}

.Body_l__bold {
  color: $fbGrey__dark;
  font: $font__bodyBold;
  font-size: 18px;
  line-height: 140%;
}

.Body {
  color: $fbGrey__dark;
  font: $font__bodyRegular;
  font-size: 16px;
  line-height: 140%;
}

.Body__bold {
  color: $fbGrey__dark;
  font: $font__bodyBold;
  font-size: 16px;
  line-height: 140%;
}

.Body_s {
  color: $fbGrey__dark;
  font: $font__bodyRegular;
  font-size: 14px;
  line-height: 140%;
}

.Body_s__bold {
  color: $fbGrey__dark;
  font: $font__bodyBold;
  font-size: 16px;
  line-height: 140%;
}
