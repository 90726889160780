@import '../../../stylesheets/variables';

.CheckEmailContainer {
  display: flex;
  text-align: center;
  flex-direction: column;
}

.CheckEmailHeader {
  font: $font__headingWidget;
  color: $fbGrey__black;
  font-weight: 900;
  font-size: 34px;
  text-transform: uppercase;
  width: 100%;
}

.CheckEmailText {
  font: $font__bodyRegular;
  color: $fbGrey__black;
  margin-top: 20px;
  margin-bottom: 16px;
  font-size: 18px;

  &.small {
    font-size: 16px;
  }
}

.CheckEmailDivider {
  margin-bottom: 40px;
  width: 50%;
}

.CheckEmailButtonContainer {
  display: flex;
  justify-content: center;
}

.SendEmail__button {
  width: 100%;
}
