@import '../variables';

.FBPaper {
  border-radius: $borderRadius__modal;
  padding: 12px;
  transition: transform 300ms cubic-bezier(0, 0, 0.2, 1) 0ms, -webkit-transform 300ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  background: white;
  font: $font__bodyRegular;
  color: $fbGrey__dark;
  border: 1px solid $fbGrey__lighter;
  box-sizing: border-box;
  box-shadow: $lightBoxShadow;
  @media (min-width: $md) {
    padding: 24px;
  }
}
