@import '../../stylesheets/variables';

.NotificationsContainer {
  position: fixed !important;
  z-index: 999999;
  bottom: 165px;
  transition: all $navigationTransitionTime;
  @media (min-width: $md) {
    bottom: 100px;
    max-width: 45vw;
  }
}

.NotificationSnackbar {
  text-align: left;
  margin: 8px 6px !important;
  position: relative !important;
  width: 100%;
  top: 74px;
  max-width: 100%;
}

.Notification {
  flex-wrap: nowrap;
  margin-right: 28px;
  padding: 8px 16px;
  min-width: unset;
}

.NotificationMessageContainer {
  padding: 4px 0;
}

.NotificationMessage {
  font: $font__bodyRegular;
  font-size: 16px;
  line-height: 140.62%;
  display: flex;
  align-items: center;
  border-radius: $borderRadius__modal;
  flex-wrap: nowrap;
}

.NotificationIcon {
  margin-right: 14px;
  margin-bottom: auto;
  width: 1.2em;
  height: 1.2em;
}

.NotificationProgressSpinner {
  width: 20px !important;
  height: 20px !important;
  color: white;
}

.success {
  background: $fbGreen__lightest;
  color: $fbGreen__standard;

  .NotificationProgressSpinner {
    color: $fbGreen__standard;
  }
}

.error {
  background: $fbRed__lightest;
  color: $fbRed__standard;

  .NotificationProgressSpinner {
    color: $fbRed__standard;
  }
}

.info {
  background: $fbBlue__standard;
  color: white;

  .NotificationProgressSpinner {
    color: white;
  }
}

.warning {
  background: $fbYellow__lightest;
  color: $fbYellow__standard;

  .NotificationProgressSpinner {
    color: $fbYellow__standard;
  }
}

.ActionsContainer {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.NotificationAction {
  margin-bottom: auto;
}
