@import '../variables';

/* stylelint-disable no-descending-specificity */
.FBButton {
  margin: 12px;
  font: $font__bodyBold;
  letter-spacing: 0.03em;
  line-height: 140.62%;
  font-size: 16.5px;
  padding: 12px 16px;
  background: $fbGreen__dark;
  border: 1px solid $fbGreen__dark;
  box-shadow: $lightBoxShadow;
  border-radius: $borderRadius__button;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: none !important;
  font-variant: normal !important;
  transition: all 400ms;
  outline: none !important;

  svg {
    transition: all 400ms;
    margin-top: -4px;
    margin-bottom: -4px;
    color: white;
  }

  span {
    text-transform: none !important;
    font-variant: normal !important;
  }

  &:hover {
    outline: none !important;
    background: $fbGreen__dark;
    box-shadow: $darkBoxShadow;

    svg {
      color: white;
    }
  }

  &:focus {
    outline: none !important;
  }

  &.secondary {
    background: white;
    color: $fbGrey__dark;
    border: 1px solid $fbGrey__standard;

    svg {
      color: $fbGrey__standard;
    }
  }

  &.danger {
    background: white;
    color: $fbRed__standard;
    border: 1px solid $fbRed__standard;

    svg {
      color: $fbRed__standard;
    }
  }

  &.success {
    background: $fbGreen__lightest;
    color: $fbGreen__dark;
    border: 1px solid $fbGreen__standard;

    svg {
      color: $fbGreen__dark;
    }
  }

  &.light_primary {
    background: linear-gradient(45deg, $fbGreen__standard 0%, $fbGreen__light 100%);
    color: white;
    border: 1px solid $fbGreen__light;

    svg {
      color: white;
    }
  }

  &.large {
    padding: 14px 18px;
  }

  &.small {
    padding: 8px 12px;
  }

  &.extra_small {
    padding: 5px 12px;
  }

  &.fullWidth {
    margin: 0;
  }

  &:disabled {
    background: rgb(25 171 79 / 50%);
    border: 1px solid transparent;
    color: white;

    svg {
      color: white;
    }

    pointer-events: initial;
    cursor: not-allowed;

    &:hover {
      background: rgb(25 171 79 / 50%);
      border: 1px solid transparent;
      box-shadow: $lightBoxShadow;
      outline: none !important;
    }

    &.secondary,
    &.light_primary,
    &.danger,
    &.success {
      box-shadow: none;
      cursor: not-allowed;
      pointer-events: initial;
      color: $fbGrey__standard;
      background: $fbGrey__lightest;
      border: 1px solid $fbGrey__light;

      svg {
        color: $fbGrey__standard;
      }
    }
  }
}
